<template>
<div class="row row-custom" v-if="Client">
    <div class="col-md-8">
        <div class="card text-right">
        <div class="card-header">
            تفاصيل  التصنيف   
                 </div>
        <div class="card-body">
            <div class="row">   
             <table class="table">
                 <tr><td>الاسم الاول </td><td>:</td><td>{{Client.first_name}}</td></tr>
                  <tr><td>الاسم الاخير </td><td>:</td><td>{{Client.last_name}}</td></tr>
                 <tr><td>البريد الالكتروني</td><td>:</td><td>{{Client.email}}</td></tr>
                 <tr><td>رقم الهاتف</td><td>:</td><td>{{Client.phone}}</td></tr>
                 <tr><td>الدولة</td><td>:</td><td>{{Client.country?Client.country.name.ar:''}}</td></tr>
                 <tr><td>المدينة</td><td>:</td><td>{{Client.city?Client.city.name.ar:''}}</td></tr>
                 <tr><td>اقرب نقطة</td><td>:</td><td>{{Client.near_place}}</td></tr>

             </table>
            </div>                      
        </div>

    </div>

    </div>
</div>

</template>

<script>
import {mapState,mapMutations} from 'vuex';
import store from '../../../store/index'
export default {
      computed:{
    ...mapState({
        Client:state=>state.admin.clients.Client.data,
        }),

      },
      methods:{
  
      },
     beforeRouteEnter(to,from,next){    
        const uuid = to.params.uuid;
        store.dispatch('admin/fetchClient', uuid).then((response) => {
            next(true);
        }).catch(error=>{
            next(false);
        })
    },
}
</script>

<style scoped>
.row-custom{
    justify-content: center;
}

.card .card-header {
    background: #16918b;
    color: white;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Tajawal';
}
.card .card-body{
    font-size: 14px;
    font-family: 'Tajawal';
    font-weight: 600;
}
</style>